<template>
  <v-chip small class="lighten-5" :class="colorClass">
    <span class="font-weight-bold" v-text="text" />
  </v-chip>
</template>

<script>
export default {
  name: "NotaFiscalChipStatus",
  props: {
    value: String,
  },
  computed: {
    options() {
      return {
        pending: { color: "orange", text: "Rascunho" },
        // ready: { color: "blue", text: "Pronta para envio" },
        // failure: { color: "red", text: "Falha ao transmitir" },
        denied: { color: "red", text: "Negada" },
        authorized: { color: "green", text: "Emitida com sucesso" },
        canceled: { color: "brown", text: "Cancelada pelo usuário" },

        // For purchase only
        purchase_pending: { color: "orange", text: "Aguardando recebimento" },
        received: { color: "green", text: "Recebida com sucesso" },
      };
    },
    colorClass() {
      const color = this.options[this.value]?.color || "grey";
      return [color, color + "--text"].join(" ");
    },
    text() {
      return this.options[this.value]?.text || this.value;
    },
  },
};
</script>
