<template>
  <v-data-table
    show-select
    must-sort
    disable-pagination
    hide-default-footer
    locale="pt"
    :loading="loading"
    :search="search"
    :headers="headers"
    :items="computedItems"
    @click:row="rowClicked"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item.data-table-select="{ isSelected, select }">
      <v-simple-checkbox
        color="green"
        :ripple="false"
        :value="isSelected"
        @input="select($event)"
      />
    </template>

    <template v-slot:item.serieNumber="{ item }">
      <span :class="{ 'text-decoration-line-through': item.canceled }">
        {{ item.serie }} / {{ item.number }}
      </span>
    </template>

    <template v-slot:item.issueDate="{ item }">
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <span
            :class="{ 'text-decoration-line-through': item.canceled }"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.issueDate | moment("L") }}
          </span>
        </template>

        <span>{{ item.issueDate | moment("LLL") }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.recipientData="{ item, isMobile }">
      <v-hover v-slot="{ hover }">
        <v-row align="center" class="py-1">
          <v-col>
            <v-menu
              v-if="item.recipient"
              top
              nudge-top="15"
              offset-y
              open-on-hover
              open-delay="500"
              transition="expand-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="font-weight-medium"
                  :class="{
                    'mr-2': !isMobile,
                    'text-decoration-line-through': item.canceled,
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span v-if="item.recipientName">
                    {{ item.recipientName }}
                  </span>

                  <cpf-cnpj-field
                    v-else
                    text
                    :type="item.recipientType"
                    :value="item.recipient.tax_id"
                  />
                </div>
              </template>

              <table-recipient-popover :value="item.recipient" />
            </v-menu>

            <span
              v-else
              class="font-weight-medium"
              :class="{ 'text-decoration-line-through': item.canceled }"
            >
              Consumidor não identificado
            </span>

            <!-- Show operation -->
            <template v-if="showOperation">
              <v-chip
                v-if="!isMobile"
                label
                small
                color="grey lighten-3"
                :ripple="false"
              >
                <v-icon left small color="primary">
                  mdi-file-document-outline
                </v-icon>
                <span class="secondary--text font-weight-regular">
                  {{ item.operationDescription }}
                </span>
              </v-chip>

              <div v-else class="secondary--text font-weight-regular">
                {{ item.operationDescription }}
              </div>
            </template>
          </v-col>

          <!-- Open in the tab -->
          <v-col v-if="!isMobile" v-show="hover" cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  target="_blank"
                  :to="item.viewHref"
                  @click.stop
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-launch</v-icon>
                </v-btn>
              </template>

              <span>Abrir em nova aba</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-hover>
    </template>

    <template v-slot:item.statusId="{ item }">
      <chip-status :value="item.status" />
    </template>

    <template v-slot:item.total="{ item }">
      <currency
        hide-symbol
        :class="{ 'text-decoration-line-through': item.canceled }"
        :value="item.total"
      />
    </template>

    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="item.pdfUrl"
            icon
            :target="!isStandalone && '_blank'"
            :href="item.pdfUrl"
            @click.stop
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-pdf-box</v-icon>
          </v-btn>
        </template>

        <span>Download do DANFE (PDF)</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="item.xmlUrl"
            icon
            :href="item.xmlUrl"
            @click.stop
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-xml</v-icon>
          </v-btn>
        </template>

        <span>Download do XML</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Currency from "@/components/Currency";
import CpfCnpjField from "@/components/CpfCnpjField";
import TableRecipientPopover from "./TableRecipientPopover";
import ChipStatus from "./ChipStatus";

export default {
  name: "NotaFiscalTable",
  components: {
    Currency,
    CpfCnpjField,
    TableRecipientPopover,
    ChipStatus,
  },
  props: {
    items: Array,
    loading: Boolean,
    showOperation: Boolean,
    search: String,
    recipientHeader: {
      type: String,
      default: "Cliente / Fornecedor",
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item) => {
        const { recipient } = item;
        const recipientName = recipient?.name || recipient?.fiscal_name || "";
        const taxId = recipient?.tax_id || "";
        const isCompany = taxId.length === 14;
        const zeroPad = (num, places) => String(num).padStart(places, "0");
        return {
          ...item,
          serieNumber: [
            // used for sorting
            zeroPad(item.serie, 3) + "/" + zeroPad(item.number, 6),
            // used for filtering
            `serie:${item.serie}`,
            `numero:${item.number}`,
            `chave:${item.sefazId}`,
          ].join(" "),
          recipientName,
          recipientType: isCompany ? "company" : "person",
          recipientData: recipient
            ? [
                // used for sorting
                recipientName,
                // used for filtering
                `nome:${recipientName}`,
                `email:${recipient.email || ""}`,
                (isCompany ? `cnpj:` : `cpf:`) + taxId,
              ].join(" ")
            : null,
          statusId: this.statusId(item.status),
        };
      });
    },
    headers() {
      return [
        {
          text: "Série / Nº",
          value: "serieNumber",
          width: this.$vuetify.breakpoint.mdOnly ? 100 : 108,
        },
        {
          text: "Emissão",
          value: "issueDate",
          filterable: false,
          width: this.$vuetify.breakpoint.mdOnly ? 100 : 109,
        },
        {
          text: this.recipientHeader,
          value: "recipientData",
        },
        {
          text: "Situação",
          value: "statusId",
          align: "center",
          filterable: false,
          width: this.$vuetify.breakpoint.mdOnly ? 100 : 184,
        },
        {
          text: "Valor (R$)",
          value: "total",
          align: "right",
          filterable: false,
          width: this.$vuetify.breakpoint.mdOnly ? 100 : 106,
          divider: true,
        },
        {
          text: "Ações",
          align: "center",
          value: "actions",
          filterable: false,
          sortable: false,
          width: this.$vuetify.breakpoint.mdOnly ? 100 : 110,
        },
      ];
    },
    ...mapGetters(["isStandalone"]),
  },
  methods: {
    rowClicked(item) {
      if (item.viewHref) {
        this.$router.push(item.viewHref);
      }
    },
    statusId(status) {
      switch (status) {
        case ("pending", "purchase_pending"):
          return 0;
        case "ready":
          return 1;
        case "failure":
          return 2;
        case "denied":
          return 3;
        case ("authorized", "received"):
          return 4;
        case "canceled":
          return 5;
      }
      return -1;
    },
  },
};
</script>

<style lang="sass" scoped>
.v-data-table::v-deep
  .v-data-table__wrapper
    table
      tbody > tr:hover
        cursor: pointer

      tbody > tr > td, thead > tr > th
        padding-right: 0 !important
        padding-left: 10px !important

        &:last-child, &.v-data-table__divider
          padding-right: 10px !important

        &.v-data-table__mobile-row:last-child
          padding-right: 0 !important
</style>
