<template>
  <v-btn-toggle multiple v-model="toggle">
    <v-btn text icon @click="prevMonth">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-menu
      offset-y
      transition="scale-y-transition"
      v-model="showCalendar"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          :block="$vuetify.breakpoint.xsOnly"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-calendar</v-icon>
          {{ value | moment("MMMM / YYYY") }}
        </v-btn>
      </template>

      <v-date-picker
        no-title
        type="month"
        locale="pt"
        :max="max"
        :min="min"
        :value="value"
        @input="changed"
      />
    </v-menu>

    <v-btn text icon :disabled="currentMonth" @click="nextMonth">
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
const MONTH_FORMAT = "YYYY-MM";

export default {
  name: "MonthPickerField",
  props: {
    max: { type: String, default: new Date().toISOString().substr(0, 7) },
    min: String,
    value: String, // YYYY-MM
  },
  data: () => ({
    showCalendar: false,
    toggle: [],
  }),
  computed: {
    currentMonth() {
      const now = this.$moment();
      const date = this.$moment(this.value);
      return now.format(MONTH_FORMAT) === date.format(MONTH_FORMAT);
    },
  },
  watch: {
    value() {
      this.toggle = this.currentMonth ? [] : [1];
    },
    toggle(v) {
      if (this.currentMonth && v.length == 0) return;
      if (!this.currentMonth && v.length == 1 && v[0] == 1) return;
      this.toggle = this.currentMonth ? [] : [1];
    },
  },
  methods: {
    changed(v) {
      this.showCalendar = false;
      this.$emit("input", v);
    },
    prevMonth() {
      const date = this.$moment(this.value).subtract(1, "months");
      this.$emit("input", date.format(MONTH_FORMAT));
    },
    nextMonth() {
      const date = this.$moment(this.value).add(1, "months");
      this.$emit("input", date.format(MONTH_FORMAT));
    },
  },
};
</script>

<style lang="sass" scoped>
.v-btn-toggle
  @media #{map-get($display-breakpoints, 'xs-only')}
    min-width: 100%

  .v-btn.v-btn--block
    min-width: 48px !important
</style>
