<template>
  <v-card>
    <v-list dense>
      <v-list-item>
        <v-list-item-avatar size="64" class="secondary lighten-4">
          <v-icon dark size="48">
            {{ icon }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-subtitle-2 font-weight-medium"
            v-text="name || 'Consumidor não identificado'"
          />
          <v-list-item-subtitle
            class="font-weight-regular secondary--text text--lighten-2"
          >
            <span v-if="isCompany">{{ value.tax_id | cnpj }}</span>
            <span v-else>{{ value.tax_id | cpf }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="address || value.phone || value.email" />

      <template v-if="address">
        <v-list-item class="dense mt-2">
          <v-list-item-icon>
            <v-icon size="20">mdi-map-marker-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ address.street }},
            {{ address.number }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="dense">
          <v-list-item-icon>
            <v-icon size="20">mdi-home-city-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div>
              <span v-if="address.zipcode">
                {{ address.zipcode | zipcode }} &dash;
              </span>
              {{ address.city.name }} / {{ address.city.state_code }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item v-if="value.phone" class="dense">
        <v-list-item-icon>
          <v-icon size="20">mdi-phone-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ value.phone | phone }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="value.email" class="dense">
        <v-list-item-icon>
          <v-icon size="20">mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <a :href="'mailto:' + value.email">
            {{ value.email }}
          </a>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item v-if="value.state_tax_number" class="dense">
        <v-list-item-icon>
          <v-icon size="20">mdi-file-percent-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <div>
            <span class="secondary--text text--lighten-2">
              Inscrição Estadual
            </span>
            <span>
              {{ value.state_tax_number }}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
  </v-card>
</template>

<script>
import FormatterFilter from "@/mixins/FormatterFilter";

export default {
  name: "NotaFiscalTableRecipientPopover",
  mixins: [FormatterFilter],
  props: {
    value: Object,
  },
  computed: {
    isCompany() {
      return this.value?.tax_id.length === 14;
    },
    icon() {
      return this.isCompany ? "mdi-office-building" : "mdi-account-tie";
    },
    name() {
      return this.value?.name || this.value?.fiscal_name;
    },
    address() {
      return this.value?.address || { city: {} };
    },
  },
};
</script>

<style lang="sass" scoped>
.v-list-item.dense
  font-size: 0.75rem !important
  font-weight: 400
  line-height: 1.25rem
  letter-spacing: normal !important
  font-family: "Roboto", sans-serif !important
  min-height: 20px

  .v-list-item__icon
    margin: 0 9px 0 0

  .v-list-item__content
    padding: 0
</style>
