var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"show-select":"","must-sort":"","disable-pagination":"","hide-default-footer":"","locale":"pt","loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.computedItems},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"green","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.serieNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.canceled }},[_vm._v(" "+_vm._s(item.serie)+" / "+_vm._s(item.number)+" ")])]}},{key:"item.issueDate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{ 'text-decoration-line-through': item.canceled }},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(item.issueDate,"L"))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.issueDate,"LLL")))])])]}},{key:"item.recipientData",fn:function(ref){
var item = ref.item;
var isMobile = ref.isMobile;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"py-1",attrs:{"align":"center"}},[_c('v-col',[(item.recipient)?_c('v-menu',{attrs:{"top":"","nudge-top":"15","offset-y":"","open-on-hover":"","open-delay":"500","transition":"expand-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font-weight-medium",class:{
                  'mr-2': !isMobile,
                  'text-decoration-line-through': item.canceled,
                }},'div',attrs,false),on),[(item.recipientName)?_c('span',[_vm._v(" "+_vm._s(item.recipientName)+" ")]):_c('cpf-cnpj-field',{attrs:{"text":"","type":item.recipientType,"value":item.recipient.tax_id}})],1)]}}],null,true)},[_c('table-recipient-popover',{attrs:{"value":item.recipient}})],1):_c('span',{staticClass:"font-weight-medium",class:{ 'text-decoration-line-through': item.canceled }},[_vm._v(" Consumidor não identificado ")]),(_vm.showOperation)?[(!isMobile)?_c('v-chip',{attrs:{"label":"","small":"","color":"grey lighten-3","ripple":false}},[_c('v-icon',{attrs:{"left":"","small":"","color":"primary"}},[_vm._v(" mdi-file-document-outline ")]),_c('span',{staticClass:"secondary--text font-weight-regular"},[_vm._v(" "+_vm._s(item.operationDescription)+" ")])],1):_c('div',{staticClass:"secondary--text font-weight-regular"},[_vm._v(" "+_vm._s(item.operationDescription)+" ")])]:_vm._e()],2),(!isMobile)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","target":"_blank","to":item.viewHref},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-launch")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir em nova aba")])])],1):_vm._e()],1)]}}],null,true)})]}},{key:"item.statusId",fn:function(ref){
                var item = ref.item;
return [_c('chip-status',{attrs:{"value":item.status}})]}},{key:"item.total",fn:function(ref){
                var item = ref.item;
return [_c('currency',{class:{ 'text-decoration-line-through': item.canceled },attrs:{"hide-symbol":"","value":item.total}})]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item.pdfUrl)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","target":!_vm.isStandalone && '_blank',"href":item.pdfUrl},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pdf-box")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download do DANFE (PDF)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item.xmlUrl)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":item.xmlUrl},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-xml")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download do XML")])])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }